<template>
    <Card title="About">
        <form class="row g-3" @submit.prevent>
            <div class="col-12">
                <h3 class="float-start">Informations</h3>
                <div class="float-end">
                    <button type="button" class="btn btn-outline-danger btn-sm m-1" @click="get()">RESET</button>
                    <button type="button" class="btn btn-outline-success btn-sm m-1" @click="save">SAVE</button>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label">Full Name</label>
                    <div class="input-group mb-3">
                    <span class="input-group-text"><i class="bi-person"></i></span>
                    <input type="text" class="form-control" :disabled="disabled.full_name" v-model="fields.full_name">
                    <button class="btn btn-outline-dark" @click="disabled.full_name = !disabled.full_name"><i class="bi" :class="{'bi-pencil-square': disabled.full_name, 'bi-check-circle-fill': !disabled.full_name}"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label">Email</label>
                    <div class="input-group mb-3">
                    <span class="input-group-text"><i class="bi-envelope"></i></span>
                    <input type="text" class="form-control" :disabled="disabled.email" v-model="fields.email">
                    <button class="btn btn-outline-dark" @click="disabled.email = !disabled.email"><i class="bi" :class="{'bi-pencil-square': disabled.email, 'bi-check-circle-fill': !disabled.email}"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label">Telephone</label>
                    <div class="input-group mb-3">
                    <span class="input-group-text"><i class="bi-telephone"></i></span>
                    <select class="form-control" :disabled="disabled.telephone" v-model="fields.telephone.country">
                        <option v-for="(val, key) in countrys" :key="key" :value="val">{{ val }}</option>
                    </select>
                    <input type="text" class="form-control" :disabled="disabled.telephone" v-model="fields.telephone.num" style="width: 50%">
                    <button class="btn btn-outline-dark" @click="disabled.telephone = !disabled.telephone"><i class="bi" :class="{'bi-pencil-square': disabled.telephone, 'bi-check-circle-fill': !disabled.telephone}"></i></button>
                    </div>
                </div>
            </div>
            <label>Profiles</label>
            <div class="col-12">
                <div class="form-group">
                    <div class="input-group mb-1">
                    <span class="input-group-text"><i class="bi-facebook"></i></span>
                    <span class="input-group-text">https://facebook.com/</span>
                    <input type="text" class="form-control" :disabled="disabled.profiles.facebook" v-model="fields.profiles.facebook">
                    <button class="btn btn-outline-dark" @click="disabled.profiles.facebook = !disabled.profiles.facebook"><i class="bi" :class="{'bi-pencil-square': disabled.profiles.facebook, 'bi-check-circle-fill': !disabled.profiles.facebook}"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <div class="input-group mb-1">
                    <span class="input-group-text"><i class="bi-instagram"></i></span>
                    <span class="input-group-text">https://instagram.com/</span>
                    <input type="text" class="form-control" :disabled="disabled.profiles.instagram" v-model="fields.profiles.instagram">
                    <button class="btn btn-outline-dark" @click="disabled.profiles.instagram = !disabled.profiles.instagram"><i class="bi" :class="{'bi-pencil-square': disabled.profiles.instagram, 'bi-check-circle-fill': !disabled.profiles.instagram}"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <div class="input-group mb-1">
                    <span class="input-group-text"><i class="bi-twitter"></i></span>
                    <span class="input-group-text">https://twitter.com/</span>
                    <input type="text" class="form-control" :disabled="disabled.profiles.twitter" v-model="fields.profiles.twitter">
                    <button class="btn btn-outline-dark" @click="disabled.profiles.twitter = !disabled.profiles.twitter"><i class="bi" :class="{'bi-pencil-square': disabled.profiles.twitter, 'bi-check-circle-fill': !disabled.profiles.twitter}"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <div class="input-group mb-1">
                    <span class="input-group-text"><i class="bi-linkedin"></i></span>
                    <span class="input-group-text">https://www.linkedin.com/in/</span>
                    <input type="text" class="form-control" :disabled="disabled.profiles.linkedin" v-model="fields.profiles.linkedin">
                    <button class="btn btn-outline-dark" @click="disabled.profiles.linkedin = !disabled.profiles.linkedin"><i class="bi" :class="{'bi-pencil-square': disabled.profiles.linkedin, 'bi-check-circle-fill': !disabled.profiles.linkedin}"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <div class="input-group mb-1">
                    <span class="input-group-text"><i class="bi-github"></i></span>
                    <span class="input-group-text">https://github.com/</span>
                    <input type="text" class="form-control" :disabled="disabled.profiles.github" v-model="fields.profiles.github">
                    <button class="btn btn-outline-dark" @click="disabled.profiles.github = !disabled.profiles.github"><i class="bi" :class="{'bi-pencil-square': disabled.profiles.github, 'bi-check-circle-fill': !disabled.profiles.github}"></i></button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label">Resume</label>
                    <div class="input-group mb-3">
                    <button class="btn btn-outline-dark form-control" @click="disabled.resume = false"><i class="bi bi-file-earmark-arrow-up-fill"></i> UPLOAD</button>
                    <button class="btn btn-outline-dark form-control" @click="download"><i class="bi bi-file-earmark-arrow-down-fill"></i> DOWNLOAD</button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label">Introduction</label>
                    <div class="input-group mb-3">
                    <textarea type="text" class="form-control" rows="5" readonly v-model="fields.intro" role="button" @click="disabled.intro = false"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label">Specialists</label>
                    <div class="input-group mb-3">
                    <input type="text" class="form-control"  v-model="specialist"/>
                    <button class="btn btn-outline-dark" @click="addSpecialist()"><i class="bi bi-plus-lg"></i></button>
                    </div>
                    <ul class="px-0">
                        <li v-for="(item, index) in fields.specialists" :key="index" @click="removeSpecialist(index)" class="btn btn-dark label">{{ item }} <span class="badge badge-light"><i class="bi bi-x-lg"></i></span></li>
                    </ul>
                </div>
            </div>
        </form>
        <Overlay v-show="!disabled.intro" @close="disabled.intro = true">
            <form class="row g-3" @submit.prevent>
                <div class="col-12">
                    <div class="form-group">
                        <div class="input-group mb-3">
                            <textarea type="text" class="form-control" rows="9" v-model="fields.intro"></textarea>
                        </div>
                        <button class="btn btn-success form-control" @click="disabled.intro = true">DONE</button>
                    </div>
                </div>
            </form>
        </Overlay>
        <Overlay v-show="!disabled.resume" @close="disabled.resume = true">
            <div class="card border-primary mb-3">
                <div class="card-header">RESUME</div>
                <div class="card-body">
                    <form class="row g-3" @submit.prevent>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <progress :value="progress" class="form-control"></progress>
                                </div>
                                <div class="input-group mb-3">
                                    <input type="file" id="file" accept=".pdf" class="form-control" @change="progress = 0" />
                                    <button class="btn btn-success" @click="upload">UPLOAD</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Overlay>
        <Overlay v-show="resume_url" @close="resume_url = null">
            <div class="card border-primary mb-3">
                <div class="card-header">RESUME</div>
                <div class="card-body">
                    <form class="row g-3" @submit.prevent>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <a class="btn btn-link" :href="resume_url" target="_blank">Resume</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Overlay>
    </Card>
</template>

<script>
import firebase from '../../firebase'
import api from '../../api'
import Card from '@/components/admin/Card.vue'
import Overlay from '@/components/admin/Overlay.vue'
export default {
    components: { Card, Overlay },
    data() {
        return {
            fields: {
                full_name: '',
                email: '',
                telephone: { country: null, num: '' },
                profiles: {
                    facebook: '',
                    instagram: '',
                    twitter: '',
                    linkedin: '',
                    github: ''
                },
                intro: '',
                specialists: []
            },
            specialist: '',
            disabled: {
                full_name: true,
                email: true,
                telephone: true,
                profiles: {
                    facebook: true,
                    instagram: true,
                    twitter: true,
                    linkedin: true,
                    github: true
                },
                intro: true,
                resume: true
            },
            countrys: [],
            progress: 0,
            resume_url: null
        }
    },
    methods: {
        get() {
            firebase.firestore().collection('infos').doc('doc').get().then(snapShot => this.fields = snapShot.data()).catch(err => console.log(err))
        },
        save() {
            firebase.firestore().collection('infos').doc('doc').set(this.fields).then(() => console.log('Updated')).catch(err => console.log(err))
        },
        getNumCodes() {
            api.get('country')
            .then(({ data }) => this.countrys = data)
            .catch(err => console.error(err))
        },
        addSpecialist() {
            if(!this.specialist.length) return console.log('required!')
            this.fields.specialists.push(this.specialist)
            this.specialist = ''
        },
        removeSpecialist(i) {
            this.fields.specialists = this.fields.specialists.filter((item, index) => index !== i)
        },
        upload() {
            const fileUploader = document.getElementById('file');
            if(!fileUploader.files.length) return console.log('required!')
            const file = fileUploader.files[0];
            const ref = firebase.storage().ref('resume.pdf');
            const task = ref.put(file);
            task.on(
                'state_changed',
                snapShot => this.progress = snapShot.bytesTransferred / snapShot.totalBytes, 
                err => console.error(err),
                () => {
                    console.log('complted!')
                    this.disabled.resume = true
                    this.download()
                }
            );

        },
        download() {
            const ref = firebase.storage().ref('resume.pdf');
            ref.getDownloadURL().then(url => {
                this.resume_url = url
            }).catch(err => console.error(err))
        }
    },
    mounted() {
        this.get()
        this.getNumCodes()
    }
}
</script>


<style scoped>
.form-control:focus {
    box-shadow: none;
}

textarea {
    resize: none;
}

.label {
    border-radius: 8px;
    padding: 2px 8px;
    margin: 4px;
}
</style>